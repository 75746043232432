import ng from 'angular';

/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider: ng.ui.IStateProvider): void {
  $stateProvider
    .state('direct-deposit', {
      redirectTo: '.landing',
      parent: 'root',
      url: '/direct-deposit',
      template: `<acp-direct-deposit-section-component></acp-direct-deposit-section-component>`,
      resolve: {
        module: /* @ngInject */ ($ocLazyLoad: any) => {
          return import(
            /* webpackChunkName: "acp.section.direct-deposit" */ 'apps/direct-deposit'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    .state('direct-deposit-mobile-panel', {
      parent: 'root',
      url: '/direct-deposit-mobile-panel',
      templateUrl:
        'apps/direct-deposit/templates/acp-direct-deposit-mobile-panel.ng.html',
      resolve: {
        module: /* @ngInject */ ($ocLazyLoad: any) => {
          return import(
            /* webpackChunkName: "acp.section.direct-deposit" */ 'apps/direct-deposit'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    .state('direct-deposit.landing', {
      template: '<acp-direct-deposit-landing></acp-direct-deposit-landing>',
      resolve: {
        module: /* @ngInject */ ($ocLazyLoad: any) => {
          return import(
            /* webpackChunkName: "acp.section.direct-deposit" */ 'apps/direct-deposit'
          ).then($ocLazyLoad.loadModule);
        }
      }
    });
}

export default states;
